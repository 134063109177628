import API from './base'
import {Deserializer} from "jsonapi-serializer";
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Notification extends API {

  async markAsReaded(notificationId) {
      try {
        const url = `users/me/notifications/${notificationId}/readings`
        const response = await this.axios.put(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }
}

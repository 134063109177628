function getTitle (vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}
export default {
  created () {
    const title = getTitle(this)

    if(title){
      this.setPageTitle(title)
    }
  },
  computed: {
    pageTitle(){
      return this.$store.state.pageTitle
    }
  },
  methods:{
    setPageTitle(pageTitle){
      if (pageTitle) {
        this.$store.state.pageTitle = pageTitle
        document.title =  pageTitle + ' - ' + process.env.VUE_APP_TITLE
      }
    }
  }
}

import API from './base'
import {Deserializer} from "jsonapi-serializer";
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Contact extends API {

  async send(formData) {
      try {
        const url = `contacts`
        const response = await this.axios.post(url, formData)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

}

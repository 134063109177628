import 'firebase/messaging'
import firebase from "firebase";

export default {
  data() {
    return {
      messaging: firebase.messaging()
    }
  },
  methods: {
    async initMessaging() {
      try {
        console.info("-- initMessaging system --")
        if (!("Notification" in window)) {
          console.warn("Este navegador no soporta notificaciones de escritorio");
          return
        }

        if (Notification.permission === 'default') {
          await this.openRequestPermissionsDialog()
        } else {
          console.info('Notificaciones ya configuradas')
        }
      } catch (e) {
        console.error('Notificaciones de navegador no soportadas.')
        console.error(e)
      }
    },
    async openRequestPermissionsDialog() {
      this.$buefy.dialog.alert({
        message: 'Recibe notificaciones en tu navegador para estar al día de toda la actualidad de Musicaeduca.',
        confirmText: 'Configurar',
        onConfirm: () => this.requestMessagingPermissions()
      })
    },
    async requestMessagingPermissions() {
      try {
        if(!('Notification' in window)){
          console.warn('Este navegador no soporta notificaciones')
          return
        }
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          console.info('Permisos de notificación concedidos')
          let token = await this.messaging.getToken()

          if (token) {
            await this.saveToken(token)
          }else{
            console.warn('token no se ha podido obtener')
          }
        } else if (permission === "denied") {
          console.warn("Notificaciones no permitidas");
        }
      } catch (error) {
        console.error("Error al solicitar permisos de notificación:", error);
      }
    },
    async saveToken(token) {
      const lastToken = localStorage.getItem('messaging_token')
      if (lastToken && lastToken !== token) {
        await this.$api.device.deleteToken(lastToken)
      }
      localStorage.setItem('messaging_token', token)
      await this.$api.device.registerToken(token)
    },
    async updateStoredToken() {
      const storedToken = localStorage.getItem('messaging_token')
      if (storedToken) {
        await this.$api.device.registerToken(storedToken)
      }

    },
    async removeStoredToken() {
      const storedToken = localStorage.getItem('messaging_token')
      if (storedToken) {
        await this.$api.device.deleteToken(storedToken)
      }
      localStorage.removeItem('messaging_token')
    }
  }
}

<template>
  <div id="app">
    <side-menu />
    <background-downloader />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
    <b-loading
      v-model="hasIncorrectOrientation"
      class="orientation-overlay"
      :is-full-page="true"
      :can-cancel="false"
    >
      <div class="orientation-block">
        <b-icon
          icon-pack="fas"
          icon="sync"
          size="is-large"
        />
        <span class="is-size-5">Cambia la orientación de tu dispositivo</span>
      </div>
    </b-loading>
    <b-loading
      v-model="isLoadingContent"
      class="general-loader"
      :is-full-page="true"
      :can-cancel="false"
    >
      <div
        v-if="hasLoadingMessage"
        class="loading-block"
      >
        <b-icon
          pack="fas"
          icon="sync"
          size="is-large"
          custom-class="fa-spin"
        />
        <div
          class="loading-msg"
          v-html="loadingMessage"
        />
      </div>
    </b-loading>
  </div>
</template>

<script>
import 'firebase/messaging'
import SideMenu from '@/components/SideMenu.vue'
import deviceTokenMixin from "@/mixins/deviceTokenMixin";
import BackgroundDownloader from "@/components/BackgroundDownloader";

export default {
  name: 'App',
  components: {
    BackgroundDownloader,
    SideMenu
  },
  mixins: [deviceTokenMixin],
  data() {
    return {
      refreshing: false,
      registration: null
    }
  },
  computed: {
    isLoadingContent() {
      return this.$store.state.isLoadingContent
    },
    loadingMessage() {
      return this.$store.state.loadingMessage
    },
    hasLoadingMessage() {
      return this.loadingMessage && this.loadingMessage !== ''
    },
    hasIncorrectOrientation() {
      return this.$store.state.neededOrientationChange
    }
  },
  async created() {
    await this.$store.dispatch('initSession')

    this.initPWAUpdateListeners()

    await this.initMessaging()
    this.initReceiveMessageHandler()
    this.initNotificationRefresh()

    try {
      console.info("--- Abriendo almacenamiento indexeddb ---")
      await this.$db.open(
        process.env.VUE_APP_INDEXEDDB_NAME,
        process.env.VUE_APP_INDEXEDDB_VERSION
      )

      console.info(this.$router.currentRoute.name)
      if (this.$router.currentRoute.name === 'playlist') {
        await this.$store.dispatch('loadPlaylist', true)
      } else {
        await this.$store.dispatch('loadPlaylist')
      }
      await this.$store.dispatch('loadExercises')
      await this.$store.dispatch('initQueue')
    } catch (e) {
      console.error('-- Error abriendo el almacenamiento indexeddb --')
      console.error(e)
    }
  },
  beforeMount() {
    const currentDomain = window.location.origin || window.location.href.slice(0, -1)
    const isDemoDomain = currentDomain.indexOf(process.env.VUE_APP_DEMO_DOMAIN) === 0
    this.$store.commit('setDemoDomain', isDemoDomain)
  },
  async mounted() {
    if (process.env.NODE_ENV === 'production') {
      document.querySelector('body').addEventListener('contextmenu', e => e.preventDefault())
    }

    this.showWelcomeMsg()

    const currentCCRevision = parseInt(process.env.VUE_APP_CC_REVISION)
    this.$cc.run(currentCCRevision, this.onAcceptCC, this.onChangeCC)
    this.$root.$on('update-token', this.updateStoredToken)
    /*
    //only testing purposes
    setTimeout(() => {
      const notif = {
        "hash": "sdfsdfasdf234234sdfa",
        "title": "Musicaeduca Online",
        "message": "Nuevo interactivo La granja de Valentina",
        "icon": "http://localhost:8081/img/logo.png",
        //"url": ''
        "url": "http://localhost:8081/albums/38"
      }

      this.onReceivedMessage(notif)
    }, 5000)
     */
  },
  methods: {
    async acceptedUpdate() {
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
    showUpdateMsg(e) {
      this.registration = e.detail
      this.$buefy.snackbar.open({
        message: '¡Nueva versión disponible!',
        type: 'is-success',
        position: 'is-top',
        actionText: 'Actualizar',
        indefinite: true,
        onAction: () => {
          this.acceptedUpdate()
        }
      })
    },
    showWelcomeMsg() {
      if(this.isAuth){
        localStorage.setItem('wellcome_msg', 'true')
        return
      }

      const wellcomeMsgViewed = localStorage.getItem('wellcome_msg') === 'true'

      if(wellcomeMsgViewed){
        return
      }

      this.$buefy.snackbar.open({
        message: 'Únete a alguna de las escuelas autorizadas Musicaeduca y accede a contenido personalizado.',
        position: 'is-bottom',
        actionText: 'Regístrate',
        duration: 10000,
        onAction: async () => {
          //localStorage.setItem('wellcome_msg', 'true')
          await this.$router.push({name: 'register'})
        }
      })
      localStorage.setItem('wellcome_msg', 'true')
    },
    initReceiveMessageHandler() {
      try {
        if(!this.messaging){
          return
        }
        this.messaging.onMessage((payload) => {
          this.onReceivedMessage(payload.data)
        });
      } catch (e) {
        console.error(e);
      }
    },
    initNotificationRefresh() {
      if (!this.isAuth || this.notifInterval !== null) {
        return
      }
      const refreshTime = process.env.VUE_APP_NOTIFICATION_REFRESH

      this.notifInterval = setInterval(() => {
        this.$store.dispatch('loadNotifications')
      }, refreshTime)
    },
    initPWAUpdateListeners(){
      document.addEventListener(
        'swUpdated', this.showUpdateMsg, { once: true }
      );
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener(
          'controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
          }
        );
      }
    },
    onReceivedMessage(data) {
      console.log("Message received. ", data);

      const notif = this.$buefy.notification.open({
        duration: parseInt(process.env.VUE_APP_NOTIFICATIONS_DURATION),
        message: `<div class="mb-1"><b>${data.title}</b></div><p>${data.message}</p>`,
        progressBar: true,
        position: 'is-top',
        type: 'is-dark'
      })

      if (data.url !== '') {
        notif.$on('click', () => {
          window.location.href = data.url
        })
      }

    },
    onAcceptCC(cookie){
      if(!this.$gtm){
        return
      }
      const categories = cookie.categories
      this.$gtm.enable(categories.includes('analytics'))
      if(!categories.includes('analytics')){
        this.removeCookieByPrefix('ga_')
      }
    },
    onChangeCC(cookie, changed_preferences){
      if(!this.$gtm){
        return
      }
      console.info("on CHANGED listener!!!!!")
      console.info(cookie, changed_preferences)
      const categories = cookie.categories

      this.$gtm.enable(categories.includes('analytics'))
      if(!categories.includes('analytics')){
        this.removeCookieByPrefix('ga_')
      }
    },
    removeCookieByPrefix(cookiePrefix){
      const cookiesArray = document.cookie.split(";");

      for(let i = 0; i < cookiesArray.length; i++)
      {
        //remove if any extra space
        const cookie = cookiesArray[i].trim();
        //to get the cookie name
        const cookieName = cookie.split("=")[0];

        // If the prefix of the cookie's name matches the one specified(i.e search), remove it
        if(cookieName.indexOf(cookiePrefix) === 0) {

          // Remove the cookie
          document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        }
      }
    }
  }
}
</script>

import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent'

class CookieConsent {
  cc = null
  onAcceptListener = null
  onChangeListener = null

  constructor() {
    this.cc = initCookieConsent();
  }
  run(revision, acceptListener = null, changeListener = null){
    this.cc.run({
      current_lang: 'es',
      autoclear_cookies: true,                   // default: false
      page_scripts: false,                        // default: false
      gui_options: {
        consent_modal: {
          layout: 'bar',               // box/cloud/bar
          position: 'bottom center',     // bottom/middle/top + left/right/center
          transition: 'zoom',           // zoom/slide
          swap_buttons: false            // enable to invert buttons
        }
      },
      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: null                     // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: true,                   // default: true
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      revision,

      onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
        //console.info('--- first time ---')
        //console.info(user_preferences, cookie)
      },

      onAccept: function (cookie) {
        if(acceptListener && (typeof acceptListener === 'function')){
          acceptListener(cookie)
        }
      },

      onChange: function (cookie, changed_preferences) {
        if(changeListener && (typeof changeListener === 'function')){
          changeListener(cookie, changed_preferences)
        }
      },

      languages: {
        'es': {
          consent_modal: {
            title: 'Usamos cookies propias y de terceros',
            description: 'Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Puede obtener más información, o bien cambiar su configuración <button type="button" data-cc="c-settings" class="cc-link">aquí</button>.',
            primary_btn: {
              text: 'Aceptar todo',
              role: 'accept_all'              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Rechazar todo',
              role: 'accept_necessary'        // 'settings' or 'accept_necessary'
            }
          },
          settings_modal: {
            title: 'Preferencias de cookies',
            save_settings_btn: 'Guardar configuración',
            accept_all_btn: 'Aceptar todo',
            reject_all_btn: 'Rechazar todo',
            close_btn_label: 'Cerrar',
            cookie_table_headers: [
              {col1: 'Nombre'},
              {col2: 'Dominio'},
              {col3: 'Expiración'},
              {col4: 'Descripción'}
            ],
            blocks: [
              {
                title: 'Uso de cookies 📢',
                description: 'Usamos cookies para asegurar el funcionamiento básico del sitio y para mejorar la experiencia de nuestros usuarios, por ejemplo para guardar tus preferencias. A continuación, puedes elegir de cada categoría aquellas que quieras aceptar/rechazar. Para más información relativa a las cookies y otros datos sensibles, lee nuestra <a href="/privacy-policy" class="cc-link">política de privacidad</a>.'
              }, {
                title: 'Cookies estrictamente necesarias',
                description: 'Estas cookies son esenciales para el correcto funcionamiento del sitio. Sin estas cookies, el sitio no podrías funcionar adecuadamente.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                }
              }, {
                title: 'Cookies de marketing',
                description: 'Estas cookies nos permiten recopilar información anónima sobre como nuestros usuarios usan nuestro sitio, qué páginas se han visitado, enlaces pulsados, etc.',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: true,
                  readonly: false
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 años',
                    col4: 'Esta cookie se utiliza para distinguir usuarios en tu sitio web. Calcula los datos de visitantes, sesiones y campañas, y realiza un seguimiento del uso del sitio para el informe de análisis del sitio. Tiene un tiempo de caducidad predeterminado de dos años.',
                    is_regex: true
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 día',
                    col4: 'Esta cookie se utiliza para distinguir usuarios en tu sitio web. Se utiliza para almacenar información sobre cómo los visitantes utilizan un sitio web y ayuda a crear un informe de análisis sobre cómo está funcionando el sitio web. Los datos recopilados incluyen el número de visitantes, su origen y las páginas visitadas. Tiene un tiempo de caducidad predeterminado de 24 horas.',
                  },
                  {
                    col1: '_gat',
                    col2: 'google.com',
                    col3: '1 minuto',
                    col4: 'Esta cookie limita las solicitudes del usuario y expira en un minuto.',
                  },
                ]
              },/* {
                title: 'Advertisement and Targeting cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false
                }
              },*/ {
                title: 'Más información',
                description: 'Para cualquier pregunta en relación a nuestra política de cookies y sus opciones, por favor <a class="cc-link" href="/contact">ponte en contacto</a> con nosotros.',
              }
            ]
          }
        }
      }
    });
  }
}

export default (Vue, options = null) => {
  Vue.prototype.$cc = new CookieConsent()
}

import {library} from '@fortawesome/fontawesome-svg-core';
// internal icons
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowUp,
  faBars,
  faCalendar,
  faCheck,
  faClock,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFolder,
  faKey,
  faPause,
  faPlay,
  faSchool,
  faStop,
  faSync,
  faTimes,
  faUnlock,
  faLink,
  faEnvelopeSquare,
  faPhone,
  faPlusCircle,
  faExternalLinkAlt,
  faSearch,
  faTimesCircle,
  faMusic,
  faFilePdf,
  faDownload,
  faThumbtack,
  faSort,
  faFont,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faGift,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faWhatsapp,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Vue from "vue";

library.add(faCaretUp, faCaretDown, faGift, faAlignLeft, faAlignRight, faAlignCenter, faAlignJustify, faFont, faSort, faThumbtack, faDownload, faFilePdf, faMusic, faTimesCircle, faSearch, faExternalLinkAlt, faPlusCircle, faPhone, faEnvelopeSquare, faInstagram, faLink, faFacebook, faWhatsapp, faTwitter, faCalendar, faFolder, faSchool, faBars, faTimes, faCheck, faEnvelope, faKey, faUnlock, faEye, faEyeSlash, faExclamationCircle, faArrowLeft, faSync, faPlay, faPause, faStop, faClock, faAngleLeft, faAngleRight, faArrowUp);

Vue.component('VueFontawesome', FontAwesomeIcon);

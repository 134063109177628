<template>
  <div class="main-menu-container">
    <header
      class="main-menu"
      :class="{'demo-header-styles': $store.state.demoDomain}"
    >
      <b-button
        class="toggler-btn"
        icon-pack="fas"
        icon-left="bars"
        @click="toggleMenu"
      />
      <div
        v-if="$store.state.demoDomain"
        class="subtitle pr-2 has-text-white has-text-weight-bold"
      >
        DEMO
      </div>
      <div class="is-flex is-align-items-center">
        <div
          v-if="!isAuth"
          class="anon-info mr-2"
          @click.prevent="openLogin"
        >
          <img
            class="avatar"
            src="@/assets/img/anon-avatar.png"
            alt="Identifícate"
          >
        </div>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-show="!open"
            v-if="isAuth"
            class="user-info mr-2"
            @click.prevent="openProfile"
          >
            <img
              class="avatar"
              :src="currentUserAvatar"
              :alt="currentUser.firstname"
            >
            <div
              v-if="unreadNotifications > 0"
              class="unread-notifications"
            />
          </div>
        </transition>
        <b-button
          v-show="!isHome"
          class="back-btn"
          icon-pack="fas"
          icon-left="arrow-left"
          @click="goBack"
        />
      </div>
    </header>


    <b-sidebar
      v-model="open"
      class="side-menu"
      type="is-dark"
      :fullheight="options.fullheight"
      :fullwidth="options.fullwidth"
      :overlay="options.overlay"
      :right="options.right"
    >
      <div>
        <session-header @change-navigation="openRoute" />

        <div>
          <b-menu>
            <b-menu-list>
              <b-menu-item
                v-for="(item, index) in menuItems"
                :key="index"
                :label="item.label"
                :active="selectedClass(item.route)"
                @click="openRoute(item)"
              />
            </b-menu-list>
          </b-menu>
        </div>
      </div>

      <img
        class="logo"
        src="@/assets/img/musicaeduca-online.png"
        alt="Musicaeduca online"
        @click.prevent="openMusicaeducaOnline"
      >
    </b-sidebar>
  </div>
</template>

<script>
import SessionHeader from "@/components/auth/SessionHeader";
import md5 from "md5";

export default {
  name: "SideMenu",
  components: {
    SessionHeader
  },
  data() {
    return {
      gravatarUrl: 'https://s.gravatar.com/avatar/{{emailmd5}}?s=40',
      options: {
        overlay: true,
        fullheight: true,
        right: false,
        fullwidth: false
      },
      open: false,
      isHome: false,
      currentRoute: null,
      ttActive: false
    };
  },
  computed: {
    menuItems() {
      return this.$store.state.menuItems
    },
    isAuth() {
      return this.$store.state.isAuth
    },
    unreadNotifications() {
      return this.$store.getters.unreadNotifications
    },
    currentUser() {
      return this.$store.state.user
    },
    currentUserAvatar() {
      const md5email = md5(this.currentUser.email)

      return this.gravatarUrl.replace('{{emailmd5}}', md5email)
    }
  },
  watch: {
    $route(newRoute) {
      //todo: change selected menu item
      //console.warn(newRoute)
      this.isHome = newRoute.name === 'home'
      this.currentRoute = newRoute.fullPath
    }
  },
  beforeMount() {
    this.isHome = this.$route.name === 'home'
    this.currentRoute = this.$route.fullPath
  },
  methods: {
    openLogin() {
      this.openRoute({route: 'login'})
    },
    openProfile() {
      this.openRoute({route: 'profile'})
    },
    selectedClass(routeName) {
      const currentRouteName = this.$router.currentRoute.name

      return routeName === currentRouteName
    },
    toggleMenu() {
      this.open = !this.open
    },
    openRoute(menuItem) {
      setTimeout(async () => {
        const routeConfig = {name: menuItem.route, params: menuItem?.params}

        if (menuItem.requiredSession && !this.isAuth) {
          let to = this.$router.resolve(routeConfig);
          let targetPath = to.href
          if (targetPath === '/profile') {
            targetPath = '/'
          }

          await this.$router.push(`/login?redirect_to=${targetPath}`)
          this.open = false
          return
        }

        try {
          await this.$router.push(routeConfig)
        } catch (e) {

        }

        this.open = false
      }, 400)
    },
    async goBack() {
      if (this.$store.state.routerInterceptorFnc) {
        this.$store.state.routerInterceptorFnc()
        return
      }
      await this.$router.go(-1)
    },
    openMusicaeducaOnline() {
      window.open("https://www.musicaeduca.es/material-educativo/app-musicaeduca/5907-test", '_blank').focus()
    }
  }
};
</script>

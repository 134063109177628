import mediaIcon from '@/assets/img/home/media-icon.png'
import albumIcon from '@/assets/img/home/album-icon.png'
import localAlbumIcon from '@/assets/img/home/local-playlist-icon.png'
import scoreIcon from '@/assets/img/home/score-icon.png'
import exerciseIcon from '@/assets/img/home/notebook-icon.png'
import presentationIcon from '@/assets/img/home/presentation-icon.png'
import articleIcon from '@/assets/img/home/article-icon.png'
import videoIcon from '@/assets/img/home/videogallery-icon.png'
import mySchoolIcon from '@/assets/img/home/my-school-icon.png'
import weeklyTasksIcon from '@/assets/img/home/weeklytask-icon.png'
import profileIcon from '@/assets/img/home/profile-icon.png'
import contactIcon from '@/assets/img/home/contact-icon.png'

const menuItems = [
  {
    label: "Principal",
    icon: albumIcon,
    requiredSession: false,
    route: "home",
    params: {},
    showInHome: false,
    envs: ['prod', 'dev']
  },
  {
    label: "Reproductor",
    icon: albumIcon,
    requiredSession: false,
    route: "albums",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Reproductor local",
    icon: localAlbumIcon,
    requiredSession: true,
    route: "playlist",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Partituras",
    icon: scoreIcon,
    requiredSession: false,
    route: "score-books",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Cuadernos",
    icon: exerciseIcon,
    requiredSession: false,
    route: "exercise-books",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Presentaciones",
    icon: presentationIcon,
    requiredSession: false,
    route: "presentations",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Interactivos",
    icon: mediaIcon,
    requiredSession: false,
    route: "media-books",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Tarea semanal",
    icon: weeklyTasksIcon,
    requiredSession: true,
    route: "tasks",
    showInHome: true,
    envs: ['prod', 'dev']
  },
  {
    label: "Vídeos",
    icon: videoIcon,
    requiredSession: false,
    route: "videos",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Comunicación",
    icon: articleIcon,
    requiredSession: false,
    route: "articles",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Mi perfil",
    icon: profileIcon,
    requiredSession: true,
    route: "profile",
    params: {},
    showInHome: true,
    envs: ['prod', 'dev']
  },
  {
    label: "Mi escuela",
    icon: mySchoolIcon,
    requiredSession: false,
    route: "schools",
    params: {},
    showInHome: true,
    envs: ['prod','dev']
  },
  {
    label: "Contacto",
    icon: contactIcon,
    requiredSession: false,
    route: "contact",
    params: {},
    showInHome: true,
    envs: ['prod', 'dev']
  }
]
const env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
export default menuItems.filter(item => item.envs.includes(env))

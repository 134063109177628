class ImageUtils {

  constructor(cdnBasePath) {
    this.CDN_BASE_PATH = cdnBasePath
  }

  getCDNFullPath(src) {
    if(src.indexOf('http') > -1){
      return src
    }
    return this.CDN_BASE_PATH + src
  }

  getYoutubeThumb(videoId){
    return `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
  }
}

const cdnBasePath = process.env.VUE_APP_BASE_CDN

export default new ImageUtils(cdnBasePath)

export default (Vue) => {
  Vue.filter("prettyDate", (value) => {
    if (!value) return ""
    const date = new Date(value)
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    return new Intl.DateTimeFormat("es", options).format(date)
  })

  Vue.filter("trunc", (value, len = 120, sufix = '...') => {
    if (!value) return ""
    if(value.length <= len) return value

    return value.substring(0, len) + sufix
  })

  Vue.filter("nl2br", (value) => {
    if (!value) return ""

    return value.split("\n").join("<br/>")
  })
}

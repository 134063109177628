import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import menuItems from '@/assets/menu-items.js'
import ImageUtils from "@/utils/image";

Vue.use(Vuex)

const TOKEN_KEY = 'token'
const USER_DATA_KEY = 'user'

export default new Vuex.Store({
  state: {
    internalUrls: [
      'https://musicaeduca.es',
      'https://interactivos.musicaeduca.online',
      'https://interactivos-dev.musicaeduca.online',
      'https://cdn.musicaeduca.es',
      'https://cdn-dev.musicaeduca.es',
      'https://webapp.musicaeduca.es',
    ],
    isLoadingContent: false,
    loadingMessage: '',
    neededOrientationChange: false,
    isAuth: false,
    token: null,
    user: null,
    notifications: [],
    pageTitle: '',
    menuItems: menuItems,
    searchQuery: '',
    searchResults: [],
    playlist: [],
    downloadList: [],
    exercises: [],
    routerInterceptorFnc: null,
    demoDomain: false,
    lastBook: null
  },
  mutations: {
    setToken(state, user) {
      state.isAuth = (user !== null)
      state.token = user && user.token ? user.token : null
      state.user = user

      if (user !== null) {
        Cookies.set(TOKEN_KEY, state.token)
        Cookies.set(USER_DATA_KEY, state.user)
      }

      if (user === null) {
        Cookies.remove(TOKEN_KEY)
        Cookies.remove(USER_DATA_KEY)
      }
    },
    setPlaylist(state, playlist) {
      state.playlist = playlist
    },
    setExercises(state, exercises) {
      state.exercises = exercises
    },
    setLoadingMessage(state, msg) {
      state.loadingMessage = msg
    },
    setLoadingContent(state, status) {
      state.isLoadingContent = status
    },
    setNeededOrientationChange(state, status) {
      state.neededOrientationChange = status
    },
    setSearchQuery(state, query = '') {
      state.searchQuery = query
    },
    setSearchResults(state, results = []) {
      state.searchResults = results
    },
    addBlobToQueue(state, song) {
      const foundIndex = state.downloadList.findIndex(item => item.id === song.id)

      if (foundIndex === -1) {
        state.downloadList.push(song)
      }
    },
    removeBlobFromQueue(state, song) {
      const foundIndex = state.downloadList.findIndex(item => item.id === song.id)

      if (foundIndex > -1) {
        state.downloadList.splice(foundIndex, 1)
      }
    },
    setBlobToQueue(state, items) {
      state.downloadList = items
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    clearNotifications(state){
      state.notifications = []
    },
    markNotificationAsReaded(state, notification){
      const findIndex = state.notifications.findIndex(item => item.id === notification.id)

      if(findIndex === -1){
        return
      }

      state.notifications[findIndex].opened_at = notification.opened_at
    },
    setRouterInterceptorFnc(state, fnc){
      state.routerInterceptorFnc = fnc
    },
    setDemoDomain(state, value){
      state.demoDomain = !!value
    }
  },
  actions: {
    async initSession({dispatch, commit, state}) {
      if(state.isAuth){
        return
      }
      const userData = Cookies.get(USER_DATA_KEY)
      const storedToken = userData ? JSON.parse(userData) : ''

      if (storedToken && storedToken !== "") {
        await dispatch('login', storedToken)
      } else {
        await dispatch('logout')
      }

      let playlistStr = localStorage.getItem('playlist') || '[]'
      const playlist = JSON.parse(playlistStr)
      commit('setPlaylist', playlist)

      this._vm.$api.init(state.token)

      dispatch('loadNotifications')
    },
    async orientationToChange({commit}, changeNeeded) {
      await commit('setNeededOrientationChange', changeNeeded)
    },
    async login({commit, dispatch}, token = null) {
      commit('setToken', token)
    },
    async logout({commit}) {
      commit('setToken', null)

      commit('clearNotifications')
    },
    async initLoading({commit}, loadingMsg = '') {
      commit('setLoadingMessage', loadingMsg)
      commit('setLoadingContent', true)
    },
    async finishLoading({commit}) {
      commit('setLoadingMessage', '')
      commit('setLoadingContent', false)
    },
    async loadPlaylist({commit, state}, withBlob = false) {
      const items = await this._vm.$db.list(process.env.VUE_APP_PLAYLIST_STORE)
      if(!items){
        console.warn("playlist not loaded")
        return
      }
      const userPlaylist = items.map(item => {
        if (state.user?.user_id !== item.user_id) {
          return null
        }

        if (withBlob === false) {
          delete item.blob
        }
        return item
      }).filter(item => item !== null)
        .sort((a, b) => {
          return a.position - b.position
        })

      commit('setPlaylist', userPlaylist)
    },
    async loadExercises({commit, state}) {
      const items = await this._vm.$db.list(process.env.VUE_APP_EXERCISE_STORE)
      if(!items){
        console.warn("exercise not loaded")
        return
      }

      const userExercises = items.map(item => {
        if (!state.user?.user_id || state.user?.user_id !== parseInt(item.user_id)) {
          return null
        }

        return item
      }).filter(item => item !== null)

      commit('setExercises', userExercises)
    },
    async initQueue({commit, state}) {
      const items = await this._vm.$db.list(process.env.VUE_APP_PLAYLIST_STORE)

      const userPlaylist = items.map(item => {
        if (state.user?.id !== item.user_id) {
          return null
        }
        item.src = ImageUtils.getCDNFullPath(item.audio)
        return item
      }).filter(item => item !== null && item.blob === null)

      commit('setBlobToQueue', userPlaylist)
    },
    async loadNotifications({commit, state}) {
      try {
        console.info('loading notifications')
        if(!state.isAuth){
          return
        }
        const notifications = await this._vm.$api.user.getNotifications()

        commit('setNotifications', notifications)
      } catch (error) {
        console.error(error)
      }
    }
  },
  getters: {
    hasStoredSearch(state) {
      return state.searchResults !== null && Array.isArray(state.searchResults) && state.searchResults.length > 0
    },
    unreadNotifications(state){
      const unread = state.notifications.filter(notification => notification.opened_at === null)

      return unread.length
    }
  },
  modules: {}
})

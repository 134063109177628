import API from './base'
import {Deserializer} from 'jsonapi-serializer'
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Album extends API {

  async getList() {
      try {
        const url = `albums`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getItemById(id) {
    try {
      const url = `albums/${id}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getLyricPdf(songId) {
    try {
      const url = `resources/album/song/${songId}/lyric/pdf?format=pdf`
      const response = await this.axios.get(url, { responseType: 'blob'})

      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

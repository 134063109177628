import {openDB} from 'idb';

class IndexedDBManager {
  db = null
  stores = {}

  async open(name, version) {
    this.stores['playlist'] = process.env.VUE_APP_PLAYLIST_STORE
    this.stores['exercise'] = process.env.VUE_APP_EXERCISE_STORE

    if (this.db) {
      this.db.close()
    }
    this.db = await openDB(name, version, {
      upgrade(db) {
        console.info('upgrade db')
        try {
          const playlist = db.createObjectStore(process.env.VUE_APP_PLAYLIST_STORE, {
            keyPath: 'id'
          });

          playlist.createIndex('id', 'id');
        } catch (e) {
          console.error("playlist ya existe en indexeddb")
        }

        try {
          const exercises = db.createObjectStore(process.env.VUE_APP_EXERCISE_STORE, {
            keyPath: 'id'
          });
          exercises.createIndex('id', 'id')
        } catch (e) {
          console.error("exercises ya existe en indexeddb")
        }
      },
    })
  }

  async list(storeName) {
    if (!this.db || !this.stores.hasOwnProperty(storeName)) {
      return
    }

    return await this.db.getAllFromIndex(this.stores[storeName], 'id')
  }

  async add(storeName, data) {
    console.warn(this.stores)
    if (!this.db || !this.stores.hasOwnProperty(storeName)) {
      return
    }
    console.warn(`adding in ${storeName}`, data)
    await this.db.add(this.stores[storeName], data);
  }

  async update(storeName, data) {
    if (!this.db || !this.stores.hasOwnProperty(storeName)) {
      return
    }
    let item = await this.db.get(this.stores[storeName], data.id);
    if (!item) {
      return
    }
    item = {...item, ...data}
    await this.db.put(this.stores[storeName], item);
  }

  async remove(storeName, id) {
    if (!this.db || !this.stores.hasOwnProperty(storeName)) {
      return
    }

    this.db.delete(this.stores[storeName], id)
  }
}

export default (Vue, options) => {
  Vue.prototype.$db = new IndexedDBManager()
}

import API from './base'
import {Deserializer} from 'jsonapi-serializer'
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class School extends API {

  async getList() {
      try {
        const url = `schools`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getItem(schoolId) {
      try {
        const url = `schools/${schoolId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }
}

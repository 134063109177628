import API from './base'
import {Deserializer} from "jsonapi-serializer";
import axios from "axios";
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export default class Search extends API {

  async search(query) {
    try {
      const url = `searchs?q=${query}`
      const requestConfig = {
        cancelToken: source.token
      }
      const response = await this.axios.get(url,requestConfig)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

<template>
  <div class="session-header">
    <a
      v-if="!isAuth"
      class="user-identify"
      href="#"
      @click="openLogin"
    >Identifícate</a>
    <div
      v-if="isAuth"
      class="user-info"
      @click.prevent="openProfile"
    >
      <img
        class="avatar"
        :src="currentUserAvatar"
        :alt="currentUser.firstname"
      >
      <b-tag
        v-if="unreadNotifications > 0"
        class="unread-notifications"
        type="is-danger"
        rounded
      >
        {{ unreadNotifications }}
      </b-tag>
      <span class="view-profile-btn">{{ currentUser.firstname }}</span>
    </div>
  </div>
</template>

<script>
import md5 from 'md5'

export default {
  name: "SessionHeader",
  data: function () {
    return {
      gravatarUrl: 'https://s.gravatar.com/avatar/{{emailmd5}}?s=40'
    }
  },
  computed: {
    unreadNotifications(){
      return this.$store.getters.unreadNotifications
    },
    currentUser(){
      return this.$store.state.user
    },
    currentUserAvatar(){
      const md5email = md5(this.currentUser.email)

      return this.gravatarUrl.replace('{{emailmd5}}',md5email)
    }
  },
  methods: {
    openLogin(){
      this.$emit('change-navigation',{requiredSession: false, route: 'login'})
    },
    openProfile(){
      this.$emit('change-navigation',{requiredSession: true, route: 'profile'})
    }
  }
}
</script>

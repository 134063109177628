import EventEmitter from 'event-emitter-es6'
import Media from './api/media'
import User from './api/user'
import Album from './api/album'
import Article from './api/article'
import School from './api/school'
import Contact from "./api/contact"
import Device from "./api/device"
import Video from "./api/video";
import Search from "./api/search";
import Task from "./api/task";
import Presentation from "./api/presentation";
import File from "./api/file";
import Score from "./api/score";
import Exercise from "./api/exercise";
import Notification from "./api/notification";

import Axios from 'axios'
import https from 'https'
import Store from '../store';
import Router from '../router';

let allowEndpoints = {
  'media': Media,
  'user': User,
  'album': Album,
  'article': Article,
  'school': School,
  'contact': Contact,
  'device': Device,
  'video': Video,
  'task': Task,
  'search': Search,
  'presentation': Presentation,
  'file': File,
  'score': Score,
  'exercise': Exercise,
  'notification': Notification
}

class Api extends EventEmitter {

  constructor(baseApi, axios) {
    super()
    this.axios = axios
    this.axios.defaults.baseURL = baseApi
    this.axios.defaults.httpsAgent = new https.Agent({rejectUnauthorized: false})

    this._initialized = false
  }

  logout() {
    this.axios.defaults.headers.common['Authorization'] = ''
    this._initialized = false
  }

  isInit() {
    return this._initialized
  }

  init(token) {
    this._initialized = true
    if (token && token !== '') {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    this.axios.interceptors.response.use((response) => {
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (403 === error.response.status) {
        console.info(error)

        Store.dispatch('logout', null)

        Router.push('/')
      }

      /*if (401 === error.response.status) {
        Router.push('/')
      }*/

      return Promise.reject(error);
    })

    return true
  }
}

let handler = {
  get: function (target, propertyKey, receiver) {
    if (allowEndpoints.hasOwnProperty(propertyKey)) {
      return new allowEndpoints[propertyKey](target.axios)
    }
    return target[propertyKey]
  }
}

export default (Vue, options) => {
  Vue.prototype.$api = new Proxy(new Api(options.baseApi, Axios), handler)
}

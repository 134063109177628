import API from './base'
import {Deserializer} from 'jsonapi-serializer'
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Presentation extends API {
  async getCategoryList() {
      try {
        const url = `presentations/categories`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getCategory(categoryId) {
      try {
        const url = `presentations/categories/${categoryId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getCategoryInfo(categoryId) {
    try {
      const url = `presentations/categories/${categoryId}/info`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getById(presentationId) {
      try {
        const url = `presentations/${presentationId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }
}

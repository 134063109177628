import API from './base'
import {Deserializer} from 'jsonapi-serializer'
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Exercise extends API {
  async getCategoryList() {
      try {
        const url = `exercises/books`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getCategory(categoryId) {
      try {
        const url = `exercises/books/${categoryId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getById(exerciseId, bookId = null) {
      try {
        let url = `exercises/${exerciseId}`
        if (bookId) {
          url += `?book_id=${bookId}`
        }
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getRandomSameBookItem(bookId, omits = null) {
    try {
      const url = `exercises/books/${bookId}/randoms?omits=${omits}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

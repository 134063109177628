import { SnackbarProgrammatic as Snackbar } from 'buefy'

class Notification{
  constructor(Snackbar) {
    this._snackbar = Snackbar
  }

  show(message){
    this._snackbar.open({
      duration: 6000,
      message: message,
      type: 'is-primary',
      position: 'is-bottom',
      actionText: 'Cerrar'
    })
  }

  success(message){
    this._snackbar.open({
      duration: 6000,
      message: message,
      type: 'is-success',
      position: 'is-bottom',
      actionText: 'Cerrar'
    })
  }

  error(message){
    this._snackbar.open({
      duration: 6000,
      message: message,
      type: 'is-danger',
      position: 'is-bottom',
      actionText: 'Cerrar'
    })
  }

}

export default (Vue, Buefy) => {
  Vue.prototype.$msg = new Notification(Snackbar)
}

<template>
  <div
    v-if="hasQueuedItems"
    class="background-downloader"
    :class="{'has-errors': error}"
  >
    <template v-if="downloading && !error">
      <b-icon
        pack="fas"
        icon="sync"
        size="is-small"
      />
      <span class="text">Descargando <span class="has-text-weight-bold">{{ currentItem.title }}</span> {{ pendingItemsTitle }}</span>
    </template>
    <template v-if="error">
      <b-icon
        pack="fas"
        icon="sync"
        size="is-small"
      />
      <span class="text">Error al descargar <span class="has-text-weight-bold">{{ currentItem.title }}</span></span>
      <a
        class="ml-1 button is-secondary is-small"
        href="#"
        @click.prevent="retryBlob"
      >Reintentar</a>
      <a
        v-if="hasMultipleItems"
        class="ml-1 button is-secondary is-small"
        href="#"
        @click.prevent="nextBlob"
      >Siguiente</a>
    </template>
  </div>
</template>

<script>
export default {
  name: "BackgroundDownloader",
  data() {
    return {
      downloading: false,
      error: false,
      currentItem: null
    }
  },
  computed: {
    hasQueuedItems() {
      return this.queue.length > 0
    },
    queue() {
      return this.$store.state.downloadList
    },
    hasMultipleItems(){
      return this.queue.length > 1
    },
    pendingItemsTitle(){
      const pendingItems = (this.queue.length -1 )
      if(pendingItems < 1){
        return ''
      }

      return `${pendingItems} en cola`
    }
  },
  watch: {
    hasQueuedItems(value) {
      if (value === true) {
        this.getBlob(this.queue[0])
      }
    }
  },
  methods: {
    async getBlob(currentItem){
      try{
        this.error = false
        this.currentItem = currentItem
        this.downloading = true

        currentItem.blob = await this.$api.file.getBlob(currentItem.src)

        await this.$db.update(process.env.VUE_APP_PLAYLIST_STORE, currentItem)

        this.removeDownloadedBlob(currentItem)
      }catch (e) {
        console.error(e)
        this.error = true
        //this.$msg.error('No se ha podido eliminar la canción del reproductor local, inténtalo más tarde')
      }
    },
    removeDownloadedBlob(item){
      this.$store.commit('removeBlobFromQueue', item)

      if(this.hasQueuedItems){
        this.getBlob(this.queue[0])
      }
    },
    retryBlob(){
      this.getBlob(this.queue[0])
    },
    nextBlob(){
      const foundIndex = this.queue.findIndex(item => item.id === this.currentItem.id)
      let nextIndex = foundIndex+1
      if(nextIndex >= this.queue.length){
        nextIndex = 0
      }
      this.getBlob(this.queue[nextIndex])
    }
  }
}
</script>

<style scoped lang="scss">
.background-downloader {
  position: fixed;
  z-index: 300;
  left: 50%;
  transform: translateX(-50%);
  top: -1px;
  background-color: black;
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.5s ease;

  &.has-errors{
    background-color: #D32C56;
  }

  .icon {
    margin-right: 0.5rem;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  .text{
    margin-left: 0.25rem;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

export default {
  data(){
    return {
      ignoreRouteHooks: false
    }
  },
  methods:{
    async handleContentError(error){
      const statusCode = error.response?.status
      this.ignoreRouteHooks = true

      if (statusCode && (statusCode === 404 ||
        statusCode === 401)) {

        if (!this.isAuth) {
          this.$msg.error('Error al acceder al contenido, prueba iniciando sesión.')
          await this.$router.push(`/login?redirect_to=${this.$route.path}`)
          return
        }

        if (this.isAuth) {
          this.$msg.error('Error al acceder al contenido')
          await this.$router.push({name: 'home'})
          return
        }

      }else{
        this.$msg.error('Error al cargar el contenido')
        await this.$router.push({name: 'home'})
      }
    }
  }
}

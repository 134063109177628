import API from './base'
import {Deserializer} from "jsonapi-serializer";

const JSONAPIDeserializer = new Deserializer({keyForAttribute: 'underscore_case'})
import parser from 'ua-parser-js'

export default class Device extends API {

  async registerToken(token) {
      try {
        const url = `devices`
        const browser = (new parser()).getResult()
        const device_platform = browser.os.name
        const device_platform_version = browser.os.version
        const response = await this.axios.post(url, {token, device_platform, device_platform_version})
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async deleteToken(token) {
      try {
        const url = `devices`
        const response = await this.axios.delete(url, {data: {token}})
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

}

import API from './base'

export default class File extends API {

  async getBlob(url){
    try {
        const response = await fetch(url)
        const blob = response.blob()

        return Promise.resolve(blob)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

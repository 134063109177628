import API from './base'
import {Deserializer} from 'jsonapi-serializer'

const JSONAPIDeserializer = new Deserializer({keyForAttribute: 'underscore_case'})

export default class Video extends API {

  async getList() {
      try {
        const url = `galleries/video`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }
  async getListByGallery(galleryId) {
    try {
      const url = `galleries/video/${galleryId}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getItemById(videoId) {
    try {
      const url = `galleries/video-item/${videoId}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

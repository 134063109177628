import API from './base'
import {Deserializer} from 'jsonapi-serializer'

const JSONAPIDeserializer = new Deserializer({keyForAttribute: 'underscore_case'})

export default class Task extends API {

  async getList() {
      try {
        const url = `tasks`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getItemBySlug(slug) {
    try {
      const url = `tasks/${slug}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

}

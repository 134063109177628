import API from './base'
import uuidv1 from 'uuid/dist/v1'
import {Deserializer} from "jsonapi-serializer";
import {isMobile, isTablet} from "mobile-device-detect";
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class User extends API {

  async getProfile() {
      try {
        const url = `users/me`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async updateProfile(userData) {
      try {
        const url = `users/me`
        const response = await this.axios.put(url, userData)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async register(userData) {
      try {
        const url = `users`
        const response = await this.axios.post(url, userData)
        return Promise.resolve(response)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async registerMinimal(userData) {
      try {
        const url = `leads`
        const response = await this.axios.post(url, userData)
        return Promise.resolve(response)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async login(email, password) {
      try {
        const uuid = uuidv1()
        const url = `auth/login`
        const mobile = isMobile || isTablet
        const response = await this.axios.post(url, {email, password, uuid, mobile})
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async logout() {
      try {
        const url = `users/me/tokens`
        const response = await this.axios.delete(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async requestChangePassword(email) {
      try {
        const url = `users/me/change-password`
        const response = await this.axios.post(url, {email})
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async changePassword(token, password) {
      try {
        const requestData = {
          token_pod: '',
          token: token,
          password: password
        }
        const url = `users/me/change-password/commit`
        const response = await this.axios.post(url, requestData)
        return Promise.resolve(response)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async associateCodes(codes){
      try {
        const requestData = codes
        const url = `users/me/codes`
        const response = await this.axios.post(url, requestData)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getNotifications(){
    try {
      const url = `users/me/notifications`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async startSession(sessionId){
      try {
        const requestData = {
          code: sessionId
        }
        const url = `users/me/session-link`
        const response = await this.axios.put(url, requestData)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async loadNextBirthdays(from, to) {
    try {
      const url = `next-birthdays?from=${from}&to=${to}`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async loadBirthdays() {
    try {
      const url = `user-birthdays`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async loadSchoolMethodGroups() {
    try {
      const url = `users/me/school-groups`
      const response = await this.axios.get(url)
      const result = await JSONAPIDeserializer.deserialize(response.data)
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addBirthday(data) {
    try {
      const url = `user-birthdays`
      const response = await this.axios.post(url, data)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async updateBirthday(id, data) {
    try {
      const url = `user-birthdays/${id}`
      const response = await this.axios.put(url, data)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteBirthday(id) {
    try {
      const url = `user-birthdays/${id}`
      const response = await this.axios.delete(url)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
